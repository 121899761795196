import React from "react";
import Layout from "../components/layout";
import AvisoPrivacidad from "../components/aviso-privacidad";

const PrivacyPage = () => {
  return (
    <Layout>
      <AvisoPrivacidad  />
    </Layout>
  );
};

export default PrivacyPage;
